import {CodeResponse} from '../../common/enums';
import {getMessageForCode} from '../../common/app';

type ResponseProps<T = null, K = null> = {
    code: CodeResponse;
    data?: T;
    meta?: K;
};

type ResponseResult<T = null, K = null> = {
    code: CodeResponse;
    message: string;
    data: T;
    meta: K;
};

export async function response<T = null, K = null>({ code, data, meta }: ResponseProps<T, K>): Promise<ResponseResult<T, K>> {
    return {
        code,
        message: getMessageForCode(code),
        data: (data !== undefined ? data : null) as T,
        meta: (meta !== undefined ? meta : null) as K,
    };
}