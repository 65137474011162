import type {ReactElement} from "react";
import {useState} from "react";
import Image from "next/image";
import {File} from 'react-feather';
import {useCopyToClipboard} from "@uxu/design-system/hooks/useCopyToClipboard";
import {InfoModal} from "@uxu/design-system/components/organisms/infoModal";
import {getCdnUrl} from "@uxu/utils/common/app/getCdnUrl";
import axios from "axios";
import {Note} from "@uxu/design-system/components/atoms/note";
import type {Status} from "@uxu/utils/common/types";
import type {OnDeleteFileProps, SectionListFilesProps} from "./types";
import styles from "./styles.module.scss";

// Function to check if the file format is an image
function isImageFormat(format: string): boolean {
    const supportedImageFormats = ["png", "jpg", "jpeg", "gif", "webp"];
    return supportedImageFormats.includes(format.toLowerCase());
}

export function SectionListFiles({data, onDelete, children}: SectionListFilesProps): ReactElement {
    const [copyStatus, copyToClipboard] = useCopyToClipboard();
    const [status, setStatus] = useState<OnDeleteFileProps>({
        bucket: null,
        region: null,
        fileName: null,
    });
    const [statusDelete, setStatusDelete] = useState<Status>("default");

    function handleModalToggle(props: OnDeleteFileProps): void {
       setStatus(props);
    }

    async function handleDeleteFile(): Promise<void> {
        if (status.bucket && status.region && status.fileName) {
            try {
                // Send DELETE request to delete the file
                const response = await axios.delete(`/api/v1/cdn`, {
                    params: {
                        bucket: status.bucket,
                        region: status.region,
                        file_name: status.fileName,
                    },
                });

                if (response.status === 200) {
                    onDelete(status);
                    setStatusDelete("success")
                    setTimeout(() => {
                        setStatusDelete("default")
                    }, 4000)
                }
            } catch (error) {
                setStatusDelete("error")
                setTimeout(() => {
                    setStatusDelete("default")
                }, 4000)
            }
        }
    }

    return (
        <>
            <InfoModal
                isOpen={status.bucket !== null && status.region !== null && status.fileName !== null}
                onClose={() => {
                    handleModalToggle({bucket: null, region: null, fileName: null});
                }}
                onDelete={() => {
                    if (status.bucket && status.region && status.fileName) {
                        void handleDeleteFile();
                        handleModalToggle({bucket: null, region: null, fileName: null});
                    }
                }}
            >
                <p>Czy na pewno chcesz usunąć {status.fileName}?</p>
            </InfoModal>
            <section className={styles.section}>
                {statusDelete === "success" && <Note typ="success">Plik został pomyślnie usunięty.</Note>}
                {statusDelete === "error" &&
                    <Note typ="error">Ups... Wystąpił błąd. Spróbuj usunąć plik ponownie.</Note>}
                <div className={styles.containerChildren}>
                    {children}
                </div>
                <div className={styles.container}>
                    {data.map((metaData) => {
                        const cdnURL = getCdnUrl(metaData)
                        const fileUrl = `${cdnURL}/${metaData.file_name}`;

                        return (
                            <div className={styles.file} key={metaData.file_name}>
                                <div className={styles.cover}>
                                    {metaData.file_format && isImageFormat(metaData.file_format) ? (
                                        <Image
                                            alt={metaData.file_name || ''}
                                            fill
                                            objectFit="cover"
                                            src={fileUrl || ''}
                                        />
                                    ) : (
                                        <File/>
                                    )}
                                </div>
                                <div className={styles.data}>
                                    <p>{metaData.file_name}</p>
                                    <span>{metaData.file_format}</span>
                                    <button
                                        className={`btn error ${styles.button}`}
                                        onClick={() => {
                                            handleModalToggle({
                                                fileName: metaData.file_name,
                                                bucket: metaData.bucket,
                                                region: metaData.region
                                            });
                                        }}
                                        type="button"
                                    >
                                        Usuń
                                    </button>
                                    <button
                                        className={`btn ${styles.button}`}
                                        onClick={() => {
                                            copyToClipboard(fileUrl);
                                        }}
                                        type="button"
                                    >
                                        {copyStatus === fileUrl ? "skopiowano" : "skopiuj link"}
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </section>
        </>
    );
}