import {type ReactElement,useCallback, useEffect, useState, useMemo} from 'react';
import {Home, Layers, Users, MessageCircle, Folder, Coffee, PhoneCall, Briefcase} from "react-feather";
import {Link} from "@uxu/design-system/components/atoms/link";
import {haveAccess} from "@uxu/utils/common/other";
import {useSiteConfig} from "@uxu/design-system/hooks/useSiteConfig";
import {useRouter} from "next/router";

export function RenderLinks(): ReactElement {
    const {config} = useSiteConfig();
    const router = useRouter();

    const [roleName, setRoleName] = useState<string>("USER_DEFAULT");
    useEffect(() => {
        const newRoleName = config.client.auth.roleName;
        if (typeof newRoleName === "string" && newRoleName !== roleName) {
            setRoleName(newRoleName);
        }
    }, [roleName, config.client.auth.roleName]);

    const isAdmin = useMemo(() => haveAccess(roleName, ["USER_ADMIN"]), [roleName]);

    const isActive = useCallback((href: string): string => {
        return router.pathname.startsWith(href) ? "active" : '';
    }, [router.pathname]);

    const adminLinks = useMemo(() => (
        <>
            <li>
                <Link className={isActive('/dashboard/blogs')} href="/dashboard/blogs" title="blogi">
                    <Coffee/>
                </Link>
            </li>
            <li>
                <Link className={isActive('/dashboard/comments')} href="/dashboard/comments" title="komentarze">
                    <MessageCircle/>
                </Link>
            </li>
            <li>
                <Link className={isActive('/dashboard/users')} href="/dashboard/users" title="użytkownicy">
                    <Users/>
                </Link>
            </li>
            <li>
                <Link className={isActive('/dashboard/files')} href="/dashboard/files" title="pliki">
                    <Folder/>
                </Link>
            </li>
            <li>
                <Link className={isActive('/dashboard/companies')} href="/dashboard/companies" title="firmy">
                    <Briefcase/>
                </Link>
            </li>
            <li>
                <Link className={isActive('/dashboard/telbook')} href="/dashboard/telbook/phones" title="telefony">
                    <PhoneCall/>
                </Link>
            </li>
            <li>
                <Link className={isActive('/dashboard/apps')} href="/dashboard/apps" title="aplikacje">
                    <Layers/>
                </Link>
            </li>
        </>
    ), [isActive]);

    return (
        <>
            <li>
                <Link href="/dashboard" title="start">
                    <Home/>
                </Link>
            </li>
            {isAdmin ? adminLinks : null}
        </>
    );
}
