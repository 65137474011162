import type {ReactElement} from "react";
import {parseFormatDate} from "@uxu/utils/common/date";
import type {SectionGridForDataProps} from "./types";
import styles from "./styles.module.scss";

export function SectionGridForData({children, data}: SectionGridForDataProps): ReactElement {

    function formatDate(label: string, date: Date | null | undefined): ReactElement {
        return (
            <li>
                <p>
                    <strong>{label}:</strong>
                    <span> {date ? parseFormatDate(date) : "null"}</span>
                </p>
            </li>
        );
    }

    return (
        <section className={styles.section}>
            <div className={styles.gridContainer}>
                <div className={styles.childrenContainer}>
                    {children}
                </div>
                <div className={styles.infoRail}>
                    <div className={styles.infoContainer}>
                        <div className={styles.contentWrapper}>
                            <div className={styles.headerWrapper}>
                                <span>Informacje</span>
                            </div>
                            <ul>
                                {formatDate("Utworzono", data?.created_at)}
                                {formatDate("Opublikowano", data?.published_at)}
                                {formatDate("Ostatnia aktualizacja", data?.modified_at)}
                                {formatDate("Usunięto", data?.deleted_at)}
                                <li>
                                    <p>
                                        <strong>Utworzone przez (id):</strong>
                                        <span> {data?.owner_id}</span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        {Boolean(data?.onDelete) && (
                            <button
                                className="btn error"
                                onClick={data?.onDelete}
                                type="button"
                            >
                                Usuń
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}