import {type ReactElement} from "react";
import {Modal} from "../../atoms/modal";
import type {InfoModalProps} from "./types";
import styles from './styles.module.scss';

export function InfoModal({isOpen = false, children, onClose, onDelete, onAdd, onSave}: InfoModalProps): ReactElement {
    return (
        <Modal className={styles.wrapper} open={isOpen} renderDirectlyInBody>
            <div className={styles.container}>
                <div className={styles.content}>
                    {children}
                </div>
                <div className={styles.footer}>
                    {Boolean(onClose) && (
                        <button className="btn" onClick={onClose} type="button">Anuluj</button>
                    )}
                    {Boolean(onAdd) && (
                        <button className="btn primary" onClick={onAdd} type="button">Dodaj</button>
                    )}
                    {Boolean(onSave) && (
                        <button className="btn primary" onClick={onSave} type="button">Zapisz</button>
                    )}
                    {Boolean(onDelete) && (
                        <button className="btn error" onClick={onDelete} type="button">Usuń</button>
                    )}
                </div>
            </div>
        </Modal>
    )
}