import axios, {type AxiosResponse} from "axios";
import type {Response, App, AppSecret} from "@uxu/utils/common/types";


export type AppsGetProps = {
    page: number;
    limit: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
}

export async function appsGet(props: AppsGetProps): Promise<Response<Array<App>>> {
    const {page, limit, sortBy, sortOrder} = props;
    const res: AxiosResponse<Response<Array<App>>> = await axios.get('/api/v1/app', {
        params: {
            page,
            limit,
            sort_by: sortBy,
            sort_order: sortOrder,
        }
    });

    return res.data;
}

export type AppGetProps = {
    id: string;
}

export async function appGet(props: AppGetProps): Promise<Response<App>> {
    const {id} = props;
    const res: AxiosResponse<Response<App>> = await axios.get(`/api/v1/app/${id}`, {});
    return res.data;
}

export type AppSecretsGetProps = {
    id: string;
}

export async function appSecretsGet(queryParams: AppSecretsGetProps): Promise<Response<Array<AppSecret>>> {
    const {id} = queryParams;
    const res: AxiosResponse<Response<Array<AppSecret>>> = await axios.get(`/api/v1/app/${id}/secret`, {});
    return res.data;
}