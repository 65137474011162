import axios, {type AxiosResponse} from "axios";
import type {App, AppProvider, AppSecret, AppTranslation, Response} from "@uxu/utils/common/types";
import {response} from "@uxu/utils/frontend/app";
import {CodeResponse} from "@uxu/utils/common/enums";

export async function appAdd(): Promise<Response<App>> {
    const res: AxiosResponse<Response<App>> = await axios.post('/api/v1/app', {});
    return res.data;
}

export type AppUpdateProps = App

export async function appUpdate(props: AppUpdateProps): Promise<Response<App>> {
    const {id} = props;
    const res: AxiosResponse<Response<App>> = await axios.put(`/api/v1/app/${id}`, {
        body: props,
    });
    return res.data;
}

export type AppDeleteProps = {
    id: string;
}

export async function appDelete(props: AppDeleteProps): Promise<Response<string>> {
    const {id} = props;
    const res: AxiosResponse<Response<string>> = await axios.delete(`/api/v1/app/${id}`, {});
    return res.data;
}

export type AppTranslationUpdateProps = AppTranslation

export async function appTranslationUpdate(props: AppTranslationUpdateProps): Promise<Response<AppTranslation>> {
    const {app_id} = props;
    const res: AxiosResponse<Response<AppTranslation>> = await axios.put(`/api/v1/app/${app_id}/translation`, {
        body: props,
    });
    return res.data;
}

export type AppProviderUpdateProps = {
    app_id: string;
    providers: Array<{ enabled: boolean } & Omit<AppProvider, 'id' | 'app_id'>>;
}

export async function appProviderUpdate(props: AppProviderUpdateProps): Promise<Response> {
    const {app_id, providers} = props;
    const providersData = providers.filter(provider => provider.enabled).map(provider => ({
        provider_id: provider.provider_id,
        app_id
    }));

    try {
        const res = await axios.put(`/api/v1/app/${app_id}/provider`, providersData);

        if (res.status === 200) {
            return response({code: CodeResponse.SUCCESS});
        }
        return response({code: CodeResponse.ERROR});

    } catch (error) {
        return response({code: CodeResponse.ERROR});
    }
}

export type AppSecretAddProps = Omit<AppSecret, 'id' | 'owner_id' | 'app' | 'owner'>

export async function appSecretAdd(props: AppSecretAddProps): Promise<Response> {
    const {app_id} = props;

    try {
        const res = await axios.post(`/api/v1/app/${app_id}/secret`, props);

        if (res.status === 200) {
            return response({code: CodeResponse.SUCCESS});
        }
        return response({code: CodeResponse.ERROR});

    } catch (error) {
        return response({code: CodeResponse.ERROR});
    }
}

export type AppSecretDeleteProps = {
    id: string
}

export async function appSecretDelete(props: AppSecretDeleteProps): Promise<Response> {
    try {
        const res = await axios.delete(`/api/v1/app/${props.id}/secret`);

        if (res.status === 200) {
            return response({code: CodeResponse.SUCCESS});
        }
        return response({code: CodeResponse.ERROR});

    } catch (error) {
        return response({code: CodeResponse.ERROR});
    }
}