import axios, {type AxiosResponse} from "axios";
import type {Response, Provider} from "@uxu/utils/common/types";


export type ProvidersGetProps = {
    page: number;
    limit: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
}

export async function providersGet(queryParams: ProvidersGetProps): Promise<Response<Array<Provider>>> {
    const { page, limit, sortBy, sortOrder } = queryParams;
    const res: AxiosResponse<Response<Array<Provider>>> = await axios.get('/api/v1/provider', {
        params: {
            page,
            limit,
            sort_by: sortBy,
            sort_order: sortOrder,
        }
    });

    return res.data;
}