import { type ReactElement, useEffect, useState } from "react";
import { Branch } from "@uxu/design-system/components/molecules/tree";
import { haveAccess } from "@uxu/utils/common/other";
import { useRouter } from "next/router";
import { useSiteConfig } from "@uxu/design-system/hooks/useSiteConfig";

export function RenderBranches(): ReactElement {
    const router = useRouter();
    const { config } = useSiteConfig();
    const [roleName, setRoleName] = useState<string>("USER_DEFAULT");

    useEffect(() => {
        const newRoleName = config.client.auth.roleName;
        if (typeof newRoleName === "string" && newRoleName !== roleName) {
            setRoleName(newRoleName);
        }
    }, [roleName, config.client.auth.roleName]);

    const isAdmin = haveAccess(roleName, ["USER_ADMIN"]);

    return (
        <>
            <Branch
                active={router.pathname === "/dashboard"}
                href="/dashboard"
                title="Start"
            />
            {isAdmin ? <>
                    <Branch
                        active={router.pathname === "/dashboard/users"}
                        href="/dashboard/users"
                        title="Użytkownicy"
                    />
                    <Branch
                        active={router.pathname === "/dashboard/apps"}
                        href="/dashboard/apps"
                        title="Aplikacje"
                    />
                </> : null}
        </>
    );
}
