import axios, {type AxiosResponse} from "axios";
import type {Response, Phones,Phone, PhoneNetworks, PhoneNetwork, PhoneRange, PhoneRanges, CodeCountry} from "@uxu/utils/common/types";


export type TelbookPhonesGetProps = {
    page: number;
    limit: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
    country: CodeCountry;
    phone?: string;
}

export async function telbookPhonesGet(props: TelbookPhonesGetProps): Promise<Response<Phones>> {
    const {page, limit, sortBy, sortOrder, country, phone} = props;


    const params: Record<string, unknown> = {
        page,
        limit,
        country,
        sort_by: sortBy,
        sort_order: sortOrder,
    };

    if (phone && phone.trim() !== '') {
        params.phone = phone;
    }

    const res: AxiosResponse<Response<Phones>> = await axios.get('/api/v1/telbook/phone', {
        params
    });

    return res.data;
}

export type TelbookPhoneGetByID = {
    id: string;
}

export async function telbookPhoneGetByID(props: TelbookPhoneGetByID): Promise<Response<Phone>> {
    const {id} = props;
    const res: AxiosResponse<Response<Phone>> = await axios.get(`/api/v1/telbook/phone/${id}`);
    return res.data;
}


export type TelbookNetworkGetProps = {
    page: number;
    limit: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
    country: CodeCountry;
    name?: string;
}

export async function telbookNetworkGet(props: TelbookNetworkGetProps): Promise<Response<PhoneNetworks>> {
    const {page, limit, sortBy, sortOrder, country, name} = props;

    const params: Record<string, unknown> = {
        page,
        limit,
        country,
        sort_by: sortBy,
        sort_order: sortOrder,
    };

    if (name && name.trim() !== '') {
        params.name = name;
    }

    const res: AxiosResponse<Response<PhoneNetworks>> = await axios.get('/api/v1/telbook/network', {
        params
    });

    return res.data;
}


export type TelbookNetworkGetByID = {
    id: string;
}

export async function telbookNetworkGetByID(props: TelbookNetworkGetByID): Promise<Response<PhoneNetwork>> {
    const {id} = props;
    const res: AxiosResponse<Response<PhoneNetwork>> = await axios.get(`/api/v1/telbook/network/${id}`);
    return res.data;
}


export type TelbookRangeGetProps = {
    page: number;
    limit: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
    country: CodeCountry;
    range?: string;
}

export async function telbookRangeGet(props: TelbookRangeGetProps): Promise<Response<PhoneRanges>> {
    const {page, limit, sortBy, sortOrder, country, range} = props;

    const params: Record<string, unknown> = {
        page,
        limit,
        country,
        sort_by: sortBy,
        sort_order: sortOrder,
    };

    if (range && range.trim() !== '') {
        params.range = range;
    }

    const res: AxiosResponse<Response<PhoneRanges>> = await axios.get('/api/v1/telbook/range', {
        params
    });

    return res.data;
}


export type TelbookRangeGetByID = {
    id: string;
}

export async function telbookRangeGetByID(props: TelbookRangeGetByID): Promise<Response<PhoneRange>> {
    const {id} = props;
    const res: AxiosResponse<Response<PhoneRange>> = await axios.get(`/api/v1/telbook/range/${id}`);
    return res.data;
}
