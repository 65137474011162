export const validateInputTelbookNetwork = {
    name: {
        required: "Nazwa jest wymagana",
        pattern: {
            value: /^[a-zA-Z0-9\s_-]+$/,
            message: "Nazwa musi zawierać przynajmniej 1 znak alfanumeryczny",
        },
    },
    tax_id: {
        required: "Identyfikator podatkowy jest wymagany",
        pattern: {
            value: /^[\p{L}\p{N}]+$/u,
            message: "Identyfikator musi zawierać przynajmniej 1 znak alfanumeryczny",
        },
    },
    country: {
        required: "Wybór kraju jest wymagany",
    },
};