import {CodeResponse} from "../enums";

export function getMessageForCode(code: CodeResponse): string {
    switch (code) {
        case CodeResponse.SUCCESS:
            return 'Request was successful';
        case CodeResponse.ERROR:
            return 'An error occurred on the server';
        case CodeResponse.INVALID_PARAMS:
            return 'Invalid parameters were provided';
        case CodeResponse.METHOD_NOT_ALLOWED:
            return 'Method not allowed';
        case CodeResponse.RESOURCE_TEMPORARILY_MOVED:
            return 'Resource temporarily moved to a new URL';
        case CodeResponse.NOT_FOUND:
            return 'not found';
        case CodeResponse.UNAUTHORIZED:
            return 'You dont have access';
        default:
            return 'Unknown response code';
    }
}