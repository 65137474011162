import type { ReactElement } from "react";
import type { DashboardHeaderProps } from "./types";
import styles from "./styles.module.scss";

export function DashboardHeader({ title, description, children }: DashboardHeaderProps): ReactElement {
    return (
        <header className={styles.header}>
            <div className={styles.left}>
                <h1>{title}</h1>
                <div>{description}</div>
            </div>
            <div className={styles.right}>
                {children}
            </div>
        </header>
    );
}
