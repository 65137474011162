import { type ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Modal } from "@uxu/design-system/components/atoms/modal";
import { Input, Select } from "@uxu/design-system/components/molecules/inputs";
import { useQuery } from "@tanstack/react-query";
import type { CodeCountry } from "@uxu/utils/common/types";
import { LoadingDots } from "@uxu/design-system/components/atoms/loading";
import { telbookNetworkGet, type TelbookNetworkGetProps } from "../../../utils";
import styles from "./styles.module.scss";
import type { NetworkModalProps } from "./types";

export function NetworkModal({ isOpen, onClose, onChoicePhoneNetwork }: NetworkModalProps): ReactElement {
    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            name: "",
            country: "pl",
        },
    });
    const [queryParams, setQueryParams] = useState<TelbookNetworkGetProps>({
        page: 1,
        limit: 12,
        country: "pl",
        sortBy: "created_at",
        sortOrder: "asc",
        name: "",
    });
    const { data: networks, isLoading } = useQuery({
        queryKey: ["telbookNetworks", queryParams],
        queryFn: () => telbookNetworkGet(queryParams),
        enabled: queryParams.name?.length ? queryParams.name.length > 0 : false,
    });

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'scroll';

        const handleGlobalKeyDown = (event: KeyboardEvent): void => {
            if (event.key === 'Escape' && isOpen) {
                onClose();
            }
        };

        document.addEventListener('keydown', handleGlobalKeyDown);

        return (): void => {
            document.body.style.overflow = 'scroll';
            document.removeEventListener('keydown', handleGlobalKeyDown);
        };
    }, [isOpen, onClose]);

    function onSubmit(formData: { name: string; country: string }): void {
        setQueryParams((prevParams) => ({
            ...prevParams,
            name: formData.name,
            country: formData.country as CodeCountry,
        }));
    }

    return (
        <Modal className={styles.wrapper} onClose={onClose} open={isOpen}>
            <div className={styles.container}>
                <div className={styles.header}><h6>Wyszukaj operatora sieci</h6></div>
                <form
                    className={styles.form}
                    onSubmit={void handleSubmit(onSubmit)}
                >
                    <Controller
                        control={control}
                        name="country"
                        render={({ field }) => (
                            <Select {...field} options={[
                                { label: "Polska", value: "pl" },
                                { label: "Niemcy", value: "de" },
                                { label: "Czechy", value: "cz" },
                                { label: "Ukraina", value: "uk" },
                            ]} />
                        )}
                    />
                    <Controller
                        control={control}
                        name="name"
                        render={({ field }) => (
                            <Input {...field} placeholder="Wpisz nazwę sieci" />
                        )}
                    />
                    <button className="btn primary" type="submit">Szukaj</button>
                </form>
                <ul className={styles.networks}>
                    {!networks?.data?.length && !isLoading && <li><h6>Brak wyników</h6></li>}
                    {networks?.data?.length ? <li><h6>Wybierz jedną z opcji</h6></li> : null}
                    {isLoading ? <li><LoadingDots size={1} /></li> : null}
                    {networks?.data?.map((network) => (
                        <li key={network.id}>
                            <button className="btn" onClick={() => {
                                onClose();
                                onChoicePhoneNetwork(network);
                                reset();
                            }} type="button">
                                {network.name}
                                <span>{network.country}</span>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </Modal>
    );
}