import type { FileMetaData } from "../types";

export function getCdnUrl(metaData: Pick<FileMetaData, 'bucket' | 'region'> & Partial<Omit<FileMetaData, 'bucket' | 'region'>>): string {
  const envUrls: Record<string, string> = {
    development: `http://${metaData.bucket}.${metaData.region}.cdn.localhost`,
    production: `https://${metaData.bucket}.${metaData.region}.cdn.uxu.pl`
  };

  const env = process.env.NEXT_PUBLIC_NODE_ENV;

  if (!env || !envUrls[env]) {
    throw new Error(`Nieznane środowisko: ${env}`);
  }

  return envUrls[env];
}