import axios, {type AxiosResponse} from "axios";
import type {PhoneNetworks, PhoneNetwork,PhoneRange, PhoneRanges,Phones, Phone, Response} from "@uxu/utils/common/types";


export async function telbookNetworkAdd(props: PhoneNetworks): Promise<Response<PhoneNetworks>> {
    const res: AxiosResponse<Response<PhoneNetworks>> = await axios.post(`/api/v1/telbook/network`, props);
    return res.data;
}


export async function telbookNetworkUpdate(props: PhoneNetwork): Promise<Response<PhoneNetwork>> {
    const res: AxiosResponse<Response<PhoneNetwork>> = await axios.put(`/api/v1/telbook/network`, props);
    return res.data;
}

export type TelbookNetworkDeleteProps = {
    id: string;
}


export async function telbookNetworkDelete(props: TelbookNetworkDeleteProps): Promise<Response> {
    const res: AxiosResponse<Response> = await axios.delete(`/api/v1/telbook/network?id=${props.id}`);
    return res.data;
}



export async function telbookRangeAdd(props: PhoneRanges): Promise<Response<PhoneRanges>> {
    const res: AxiosResponse<Response<PhoneRanges>> = await axios.post(`/api/v1/telbook/range`, props);
    return res.data;
}


export async function telbookRangeUpdate(props: PhoneRange): Promise<Response<PhoneRange>> {
    const res: AxiosResponse<Response<PhoneRange>> = await axios.put(`/api/v1/telbook/range`, props);
    return res.data;
}

export type TelbookRangeDeleteProps = {
    id: string;
}


export async function telbookRangeDelete(props: TelbookRangeDeleteProps): Promise<Response> {
    const res: AxiosResponse<Response> = await axios.delete(`/api/v1/telbook/range?id=${props.id}`);
    return res.data;
}


export async function telbookPhoneAdd(props: Phones): Promise<Response<Phones>> {
    const res: AxiosResponse<Response<Phones>> = await axios.post(`/api/v1/telbook/phone`, props);
    return res.data;
}


export async function telbookPhoneUpdate(props: Phone): Promise<Response<Phone>> {
    const res: AxiosResponse<Response<Phone>> = await axios.put(`/api/v1/telbook/phone`, props);
    return res.data;
}

export type TelbookPhoneDeleteProps = {
    id: string;
}


export async function telbookPhoneDelete(props: TelbookPhoneDeleteProps): Promise<Response> {
    const res: AxiosResponse<Response> = await axios.delete(`/api/v1/telbook/phone?id=${props.id}`);
    return res.data;
}
