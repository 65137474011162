import axios, {type AxiosResponse} from "axios";
import type {Response, FileMetaData} from "@uxu/utils/common/types";


export type FilesMetaDataGetProps = Pick<FileMetaData, 'bucket' | 'region'> & Partial<Omit<FileMetaData, 'bucket' | 'region'>> & {page: number, limit: number}


export async function filesMetaDataGet(props: FilesMetaDataGetProps): Promise<Response<Array<FileMetaData>>> {
    const {page, limit, bucket, region} = props;
    const res: AxiosResponse<Response<Array<FileMetaData>>> = await axios.get('/api/v1/cdn', {
        params: {
            page,
            limit,
            bucket,
            region,
        }
    });

    return res.data;
}
