import type {ReactElement} from "react";
import type {SectionDashboardProps} from "./types";
import { RenderLinks} from "./components";
import styles from "./sectionDashboard.module.scss";

export function SectionDashboard({children}: SectionDashboardProps): ReactElement {
    return (
        <section className={styles.wrapper}>
            <div className={styles.menu}>
                <ul>
                    <RenderLinks/>
                </ul>
            </div>
            <div className={styles.content}>{children}</div>
        </section>
    );
}
