import { useState, useCallback } from 'react';

type CopyToClipboardHook = [string | null, (text: string) => void];

export const useCopyToClipboard = (): CopyToClipboardHook => {
  const [copiedText, setCopiedText] = useState<string | null>(null);

  const copy = useCallback((text: string) => {
    if (copiedText !== text) {
      navigator.clipboard.writeText(text).then(() => {
        setCopiedText(text);
        setTimeout(() => {
          setCopiedText(null);
        }, 1000);
      }).catch(() => { /* empty */ });
    }
  }, [copiedText]);

  return [copiedText, copy];
};
