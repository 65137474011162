import type {ReactElement} from "react";
import { ColorShiftTextAnimator } from "@uxu/design-system/components/atoms/colorShiftTextAnimator";
import { Link } from "@uxu/design-system/components/atoms/link";
import { useSiteConfig } from "@uxu/design-system/hooks/useSiteConfig";
import styles from './sectionHello.module.scss';
import { header, description, buttonLeft, buttonRight } from './consts';

export function SectionHello(): ReactElement {
    const { config } = useSiteConfig();
    const dashboardUrl = encodeURIComponent(`${config.site.canonicalUrl}/dashboard`);


    const links = [
        {
            className: "btn primary large",
            href: `/api/v1/auth/token?redirect_uri=${dashboardUrl}`,
            title: buttonLeft,
            content: buttonLeft,
        },
        {
            className: "btn special large",
            href: `/api/v1/auth/token?redirect_uri=${dashboardUrl}&sing_up=true`,
            title: buttonRight,
            content: buttonRight,
            hasSpans: true,
        },
    ];

    return (
        <section className={styles.section}>
            <ColorShiftTextAnimator description={description} header={header} />
            <div className={styles.wrapperButtons}>
                {links.map((link, index) => (
                    <Link
                        className={link.className}
                        href={link.href}
                        key={index}
                        title={link.title}
                    >
                        {link.hasSpans ? <><span/><span/><span/></> : null}
                        {link.content}
                    </Link>
                ))}
            </div>
        </section>
    );
}
